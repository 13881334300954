import {CustomDateModel} from '../CustomDate/custom-date.model';

export class EventModel {
  id?: string;
  type?: string;
  event_type?: string;
  description?: string;
  created_at?: CustomDateModel;
  updated_at?: CustomDateModel;

  constructor() {
    this.created_at = new CustomDateModel();
    this.updated_at = new CustomDateModel();
  }

}
