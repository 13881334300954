import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';


import {AppComponent} from './app.component';
import {HomeComponent} from './components/home/home.component';
import {AppRoutingModule} from './app-routing/app-routing.module';
import {NavDrawerComponent} from './components/nav-drawer/nav-drawer.component';
import {MaterialModule} from './material/material.module';
import {ToolBarComponent} from './components/tool-bar/tool-bar.component';
import {SharedModule} from './shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {EditCustomerComponent} from './components/customer/edit-customer/edit-customer.component';
import {MessageDialogComponent} from './dialogs/message-dialog/message-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { AlertMessageDialogComponent } from './dialogs/alert-message-dialog/alert-message-dialog.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MerchantAccountActivationComponent } from './components/merchant-account-activation/merchant-account-activation.component';
import { RegistrationComponent } from './components/registration/registration.component';
import {CurrencyPipe, DatePipe, DecimalPipe} from '@angular/common';
import { EventComponent } from './components/event/event.component';
import { EventListingComponent } from './components/event-listing/event-listing.component';
import { EventDetailsComponent } from './components/event-details/event-details.component';
import { UserRoleComponent } from './user-role/user-role.component';
import {CreditCardDirectivesModule} from 'angular-cc-library';
import {AuthGuardService} from './data/services/AuthGuard/auth-guard.service';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {ChartsModule} from './chartsModule/charts.module';
import { ProfileDialogComponent } from './dialogs/profile-dialog/profile-dialog.component';
import { CreateNewPaymentSwipeComponent } from './dialogs/create-new-payment-swipe/create-new-payment-swipe.component';
import {MatSelectModule} from '@angular/material/select';
import {MatMenuModule} from '@angular/material/menu';
import { CreateNewAccountDialogComponent } from './dialogs/create-new-account-dialog/create-new-account-dialog.component';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {enGbLocale} from 'ngx-bootstrap/locale';
defineLocale('en-gb', enGbLocale);
// import { WebhooksComponent } from './components/webhooks/webhooks.component';
import { AccountListComponent } from './components/connect/account-list/account-list.component';
import { AccountDetailsComponent } from './components/connect/account-details/account-details.component';
import { AccountSettingsComponent } from './components/connect/account-settings/account-settings.component';
import { CreateConnectAccountComponent } from './components/connect/create-connect-account/create-connect-account.component';
import { CollectedFeesListComponent } from './components/connect/collected-fees-list/collected-fees-list.component';
import { CollectedFeesDetailsComponent } from './components/connect/collected-fees-details/collected-fees-details.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import {DisputesComponent} from './paymemtModule/components/disputes/disputes.component';
import {DisputeDetailsComponent} from './paymemtModule/components/dispute-details/dispute-details.component';
import {DisputesFormComponent} from './paymemtModule/components/disputes-form/disputes-form.component';
import { LoaderComponent } from './components/loader/loader.component';
import { CreateNewBridgeAccountComponent } from './dialogs/create-new-bridge-account/create-new-bridge-account.component';
import { ManualInvoicePaymentComponent } from './components/manual-invoice-payment/manual-invoice-payment.component';
import { AccessAccountDialogComponent } from './dialogs/access-account-dialog/access-account-dialog.component';
import { AdminComponent } from './components/admin/admin.component';
import {CreditService} from './credit.service';
import { AccountBrandingComponent } from './components/account-branding/account-branding.component';
import { FooterComponent } from './components/footer/footer.component';
import { StatementsComponent } from './components/statements/statements.component';
import { UpdateContactDialogComponent } from './dialogs/update-contact-dialog/update-contact-dialog.component';
import { CustomersModule } from './customers/customers.module';
import { SubscriptionComponent } from './subscriptionModule/subscription/subscription.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { MultiFactorAuthenticationComponent } from './multi-factor-authentication/multi-factor-authentication.component';
import { SetupVerificationDialogComponent } from './dialogs/setup-verification-dialog/setup-verification-dialog.component';
import { SecurityCodeDialogComponent } from './dialogs/security-code-dialog/security-code-dialog.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule } from '@angular/common/http';
import { SwitchAccountDialogComponent } from './dialogs/switch-account-dialog/switch-account-dialog.component';
import { UserProfileDialogComponent } from './dialogs/user-profile-dialog/user-profile-dialog.component';
import { MerchantBridgeModule} from '../app/merchant-bridge-module/merchant-bridge-module.module';
import { PaymentAchDetailsComponent } from './paymemtModule/components/payment-ach-details/payment-ach-details.component';
import { ManualAchInvoicePaymentComponent } from './components/manual-ach-invoice-payment/manual-ach-invoice-payment.component';
import { CustomerAchDirectChargeComponent } from './paymemtModule/components/customer-ach-direct-charge/customer-ach-direct-charge.component';
// import { PaymentAchListComponent } from './paymemtModule/components/payment-ach-list/payment-ach-list.component';

// import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavDrawerComponent,
    ToolBarComponent,
    EditCustomerComponent,
    MessageDialogComponent,
    SignInComponent,
    AlertMessageDialogComponent,
    MerchantAccountActivationComponent,
    RegistrationComponent,
    EventListingComponent,
    EventDetailsComponent,
    UserRoleComponent,
    ProfileDialogComponent,
    CreateNewPaymentSwipeComponent,
    CreateNewAccountDialogComponent,
    // WebhooksComponent,
    AccountListComponent,
    AccountDetailsComponent,
    AccountSettingsComponent,
    CreateConnectAccountComponent,
    CollectedFeesListComponent,
    CollectedFeesDetailsComponent,
    ForgotPasswordComponent,
    DisputesComponent,
    DisputeDetailsComponent,
    DisputesFormComponent,
    LoaderComponent,
    CreateNewBridgeAccountComponent,
    ManualInvoicePaymentComponent,
    AccessAccountDialogComponent,
    AdminComponent,
    AccountBrandingComponent,
    FooterComponent,
    StatementsComponent,
    UpdateContactDialogComponent,
    MultiFactorAuthenticationComponent,
    SetupVerificationDialogComponent,
    SecurityCodeDialogComponent,
    SwitchAccountDialogComponent,
    UserProfileDialogComponent,
    PaymentAchDetailsComponent,
    ManualAchInvoicePaymentComponent,
    CustomerAchDirectChargeComponent,
    // PaymentAchListComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    SharedModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    SharedModule,
    CreditCardDirectivesModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatMenuModule,
    ChartsModule,
    CustomersModule,
    FontAwesomeModule,
    HttpClientModule,
    MerchantBridgeModule,

    // TextMaskModule
  ],
  exports: [EventComponent, FormsModule],
  providers: [DatePipe, CurrencyPipe, DecimalPipe, CreditService, SubscriptionComponent,
    { provide: 'LOCALSTORAGE', useFactory: getLocalStorage }, AuthGuardService],
  bootstrap: [AppComponent]

})
export class AppModule {
}
export function getLocalStorage() {
  return (typeof window !== 'undefined') ? window.localStorage : null;
}

