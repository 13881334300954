import { Component, OnInit } from '@angular/core';
import {EventListAction} from '../event/ApiAction/EventListAction';
import {ApiResponseModel} from '../../data/model/ApiResponseModel/api-response.model';
import {EventModel} from '../../data/model/EventModel/Event.model';
import {AppConstants} from '../../shared/constants/app-constants';
import {DataManagerService} from '../../data/services/DataManager/data-manager.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css']
})
export class EventDetailsComponent extends EventListAction implements OnInit {

  dataManager: DataManagerService;
  eventData: EventModel;

  /*eventJson =  {
      'object': 'charges',
      'id': 'nvajm67v9gbxrlko',
      'type': 'charges.created',
      'description': 'Payment of $12 is created ',
      'created_at': {
        'date': '2018-04-15 13:46:37.000000',
        'timezone_type': 3,
        'timezone': 'UTC'
      },
      'updated_at': {
        'date': '2018-04-15 13:46:37.000000',
        'timezone_type': 3,
        'timezone': 'UTC'
      },
      'relations': {
        'data': {
          'object': 'Charge',
          'id': 'wXwMaypayrp9reqV',
          'real_id': 15,
          'amount': 12,
          'payarc_fees': 0.38400000000000006,
          'type': 'Credit',
          'net_amount': 11.616,
          'amount_refunded': null,
          'captured': 1,
          'status': 'Submitted For Settlement',
          'auth_code': 'TAS914',
          'failure_code': null,
          'failure_message': null,
          'charge_description': 'statement description',
          'created_at': '2018-04-15 13:46:37',
          'updated_at': '2018-04-15 13:46:37',
          'refund': {
            'data': []
          },
          'card': {
            'data': {
              'object': 'Card',
              'id': 'dOOkYEljOYjzdBGY',
              'real_id': 2,
              'card_holder_name': null,
              'is_default': 1,
              'exp_month': '12',
              'exp_year': '2020',
              'address1': null,
              'address2': null,
              'city': null,
              'state': null,
              'zip': null,
              'brand': 'V',
              'last4digit': '5439',
              'first6digit': null,
              'country': null,
              'avs_status': '0',
              'cvc_status': 'M',
              'address_check_passed': 0,
              'zip_check_passed': 0,
              'customer_id': 'VaVqlWOXVqXDn89J',
              'created_at': {
                'date': '2018-04-11 03:39:01.000000',
                'timezone_type': 3,
                'timezone': 'UTC'
              },
              'updated_at': {
                'date': '2018-04-13 14:52:22.000000',
                'timezone_type': 3,
                'timezone': 'UTC'
              }
            }
          }
        }
      }
    };*/

  constructor(dataManager: DataManagerService, router: Router, route: ActivatedRoute) {
    super();
    this.dataManager = dataManager;
    this.errorMsg = null;
    this.router = router;
    this.route = route;
    this.eventData = new EventModel();
    if (this.fetchIdFromRouteQueryParams()) {
      this.route.queryParams.subscribe((params) => {
        this._eventListUrl = AppConstants.BASE_URL + AppConstants.SERVICE_NAME_EVENT + '/' + params['id'];
      });
      this.getEventListData();
    }

  }

  ngOnInit() {
  }
  protected passEventList(eventResponse) {
    this.loadFlag = false;
    this.eventData = eventResponse.data;
    console.log('eventResponse', eventResponse);
    console.log('eventData', this.eventData);
  }

  protected passError(error: any) {
    this.loadFlag = false;
  }


}
