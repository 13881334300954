<app-details-loader *ngIf="loadFlag || errorMsg" [errorMsg]="errorMsg" [Height]="'70vh'" [LoaderMarginTop]="'19%'" ></app-details-loader>


  <div  class="row" *ngIf="!loadFlag && !errorMsg">
    <div  class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
      <h3  class="headings">Events & logs</h3>
    </div>
  </div>
  <div class="event-bdy">
    <div class="row">
          <div class="col-sm-12 col-md-12 col-xl-12 col-lg-12 col-12 pt-m-16">
            <h4 class="head-text" >{{!eventData.description ? '----' : (eventData.description | camalizeStr : true).replace('usd', "USD") }}</h4>
          </div>
  </div>
    <div class="clearfix">
    <div class="b_shadow">
      <div class="heading-card col-sm-12 col-md-12 col-xl-12 col-lg-12 col-12">
        <div class="row m-0">
          <div class="col-sm-6 col-md-6 col-xl-6 col-lg-6 col-6 left-heading pl-4">
            <span>Event details</span>
          </div>
        </div>
      </div>

        <div class="pl-sm-4 pl-2 mt-sm-0 mt-2 col-sm-12 col-md-12 col-xl-12 col-lg-12 col-12">
            <div class="row py-sm-2 py-0 inner-details-subsc">
              <div class="col-lg-2 col-md-4 col-xl-2 col-sm-4 col-5 left-heading">
                ID:
              </div>
              <div class="col-lg-8 col-md-8 col-sm-8 col-xl-8 col-7 right-data">
                <span>{{!eventData.id ? '----' : eventData.id}}</span>
              </div>
            </div>
            <div class="row py-sm-2 py-0 inner-details-subsc">
              <div class="col-lg-2 col-md-4 col-sm-4 col-xl-2 col-5 left-heading">Created:</div>
              <div class="col-lg-8 col-md-8 col-sm-8 col-xl-8 col-7 right-data">
                <span>{{!eventData.created_at ?  '----' : eventData.created_at | dateFormat: 'MM/dd/yyyy hh:mm'}}</span>
              </div>
            </div>
            <div class="row py-sm-2 py-0 inner-details-subsc">
              <div class="col-lg-2 col-md-4 col-sm-4 col-xl-2 col-5 left-heading">
                Type:
              </div>
              <div class="col-lg-8 col-md-8 col-sm-8 col-xl-8 col-7 right-data">
                <span>{{!eventData.type ? '----' : eventData.type}} </span>
              </div>
            </div>
            <div class="row py-sm-2 py-0 inner-details-subsc">
              <div class="col-lg-2 col-md-4 col-sm-4 col-xl-2 col-5 left-heading">Description:</div>
              <div class="col-lg-8 col-md-8 col-sm-8 col-xl-8 col-7 right-data">
                <span>{{eventData.description  ? (eventData.description | camalizeStr : true).replace('usd', "USD") :  '----' }}</span>
              </div>
            </div>
        </div>
    </div>
    </div>


    <div class="clearfix">
      <div class="b_shadow mt-4">
      <div class="heading-card col-lg-12 col-md-12 col-sm-12 col-xl-12 col-12">
        <div class="row m-0">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xl-6 col-6 left-heading pl-4">
            <span>Event data</span>
          </div>

        </div>
      </div>

        <div class="pl-4 mt-0 col-lg-12 col-md-12 col-sm-12 col-xl-12 col-12">
          <pre>{{!eventData  ?  'No Details Available!': eventData | json }}</pre>
        </div>



    </div>


</div>
</div>
