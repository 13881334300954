<div class="payment-bdy">
<div class="container-fluid p-0">
  <div class="row">
    <div class="col-md-12 col-lg-12 col-sm-12 col-12 col-xl-12 px-m-0">
      <span class="head-email" *ngIf="charge.customer_name">{{charge.customer_name}}</span>
      <span class="head-text customer_id" *ngIf="charge.customer_id">{{charge.customer_id}}</span>
    </div>
    <div class="col-md-12 col-lg-12 col-sm-12 col-12 col-xl-12 px-m-0 email-div">
      <span class="head-text" *ngIf="charge.customer_email">{{charge.customer_email}}</span>
    </div>
  </div>
  <div class="col-md-12 col-lg-12 col-sm-12 col-12 col-xl-12 b_shadow mt-5 padding-m">
    <div class="row bg-infos py-4">
      <div class="col-md-12 header pl-4">
        Create new payment
      </div>
    </div>
    <ng-container *ngIf="topErrorMessage">
      <div class="row err" *ngFor="let obj of keys1 ">
        <div class="col-md-12" *ngFor="let value of obj ">
          <p class="col-md-12 error" id="err">{{value}}</p>
        </div>
      </div>
    </ng-container>
    <div class="row" *ngIf="!topErrorMessage">
      <div class="col-md-12 error mb-3 mt-3"> {{errorMsg}}</div>
    </div>
    <ng-container *ngIf="(charge.amount < (charge.prescription + charge.vision + charge.clinic + charge.dental))">
      <div class="col-md-12 text-center">
        <label class="error err" id="error">Total amount exceeded {{charge.amount}}</label>
      </div>
    </ng-container>
    <div class="row" *ngIf="checkTextField()">
      <div class="col-md-12 text-center">
        <label class="error" id="error1">Only three fields allowed</label>
      </div>
    </div>
    <form #capturePaymentDialog="ngForm" class="px-3">
      <div class="form-group row m-0">
        <div class="col- level-text mr-sm-3 mr-lg-5" [class.level-text-active]="card_level === 'level1'"
             (click)="levelChange('level1')">Charge
        </div>
        <div class="col- level-text mr-sm-3 mr-lg-5" [class.level-text-active]="card_level === 'level2'"
             (click)="levelChange('level2')">Level 2
        </div>
        <div class="col- level-text" [class.level-text-active]="card_level === 'level3'"
             (click)="levelChange('level3')">
          Level 3
        </div>
      </div>
      <div class="row mt-sm-4 mt-1 ml-0">
        <div class="col-12 col-md-12 col-lg-6 col-sm-12 col-xl-6 px-m-0">
          <div class="form-group row pt-m-18">
            <label for="currency" class="col-sm-4 col-12 p-0">Currency:</label>
            <span class="error_sign col-2" id="currency1">!</span>
            <div class="col-sm-7 col-12 px-m-0">
              <select class="form-control" id="currency" name="currency" required #currencyInput="ngModel"
                      [(ngModel)]="charge.currency">
                <option *ngFor="let currency of currencyList" [attr.selected]="charge.currency === currency.name"
                        [value]="currency.name">{{currency.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row pt-m-18">
            <label class="col-sm-4 col-12 p-0">
              <span>Amount:</span><i class="required-icon">*</i>
            </label>
            <span class="error_sign col-2" id="amount1">!</span>
            <div class="col-sm-7 col-10 pl-m-0">
              <ng-container *ngIf="!disableAmount">
                <input #amountInp="ngModel" class="form-control amountInput" id="amount" appMinMaxValidator
                       appAmountValidator step="0.01" min="0.01" max="9999999" autocomplete="off"
                       (change)="checkAmount()"
                       name="amount" required pattern="\d+(\.\d{1,2})?" (keyup)="minMaxCheck(charge.amount)"
                       (keypress)="_keyPress($event)" [(ngModel)]="charge.amount"
                       [class.invalid]="capturePaymentDialog.submitted && amountInp.invalid" type="number" value=""
                       placeholder="0.00">
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && amountInp.errors?.required">Amount is required!</span>
                <span class="validation-err-msg" *ngIf="!amountInp.errors?.required && minError === true">Amount should
                not be less than $0.5.</span>
              </ng-container>
              <label *ngIf="disableAmount" class=" ">{{charge.amount | currency:'USD':'symbol': '1.2-2'}}</label>
            </div>
            <ng-template #amountInpTemplate>
              <div class="text-left">
                <b>Description: </b>The amount to be processed and includes the subtotal and any additional charges.
                If a request transaction amount includes a decimal point, the response will also include a decimal
                point.
                For void and return transactions, this is a conditional field. If this is not included in the request,
                then the full amount of the original transaction is voided.<br>
              </div>
            </ng-template>
            <div class="col-sm-1 col-2 pl-0 pt-1 text-sm-left text-right"><i placement="left" [tooltip]="amountInpTemplate" container="body"
                                            class="fa pointer fa-lg fa-question-circle"></i></div>
          </div>
          <div class="form-group row pt-m-18">
            <label class="col-sm-4 col-12 p-0">Surcharge:</label>
            <div class="col-sm-7 col-12 pr-m-0">
              <div class="row justify-content-between">
                <div class="col-md-1 col-sm-2 surcharge">
                  <input type="checkbox" class="form-check-input" [(ngModel)]="surcharge_check"
                         (ngModelChange)="sur_change()"
                         [ngModelOptions]="{standalone: true}">
                </div>
                <div class="col-md-10 col-sm-10 cus_surcharge pr-m-0">
                  <input type="text" class="form-control" [value]="surcharge_display_value" readonly>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row pt-m-18">
            <label class="col-sm-4 col-12 p-0">Total amount:</label>
            <div class="col-sm-7 col-12 px-m-0">
              <input type="text" class="form-control amountInput" [ngModel]="total_amount | number : '1.2-2'"
                     [ngModelOptions]="{standalone: true}" placeholder="0.00" readonly>
              <span class="validation-err-msg" *ngIf="maxError === true">Amount should be less than $1,000,000.</span>
            </div>
          </div>
          <div class="form-group row pt-m-18">
            <label class="col-sm-4 col-12 p-0">Description:</label>
            <span class="error_sign col-2" id="charge_description1">!</span>
            <div class="col-sm-7 col-12 px-m-0">
              <input class="form-control" id="charge_description" name="charge_description"
                     #charge_description="ngModel"
                     [(ngModel)]="charge.charge_description" type="text" value="" maxlength="190">
            </div>
          </div>
          <div class="form-group row pt-m-18">
            <label for="statement_description" class="col-sm-4 col-12 p-0">Statement descriptor:<i
              class="required-icon">*</i></label>
            <span class="error_sign col-2" id="statement_description1">!</span>
            <div class="col-sm-7 col-12 px-m-0">
              <input class="form-control" type="text" id="statement_description" name="statement_description" required
                     [class.invalid]="capturePaymentDialog.submitted && charge_description.invalid"
                     #charge_description="ngModel" minlength="5" [(ngModel)]="charge.statement_description"
                     maxlength="25">
              <span class="validation-err-msg"
                    *ngIf="capturePaymentDialog.submitted && charge_description.errors?.required">Statement descriptor is
              required!</span>
              <span class="validation-err-msg" *ngIf=" charge_description.errors?.maxlength">Statement descriptor is
              exceeding max limit of 25 characters!</span>
              <span class="validation-err-msg" *ngIf=" charge_description.errors?.minlength">Statement descriptor must be
              at least 5 characters.</span>
            </div>
          </div>
          <div class="form-group row pt-m-18">
            <label class="col-sm-4 col-12 p-0">Source:</label>
            <span class="error_sign col-2" id="card_id1">!</span>
            <div class="col-sm-7 col-12 px-m-0">
              <app-default-select (selectedCard)="cardSelect($event)" [cardList]="data.sourceList" id="card_id"
                                  [defaultObject]="card"></app-default-select>
            </div>
          </div>
          <div class="form-group row pt-m-18">
            <label class="col-sm-4 col-12 p-0">CVV:<i *ngIf="card.is_verified === 0 && cvv_optional === 0"
                                            class="required-icon">*</i></label>
            <span class="error_sign col-2" id="cvv1">!</span>
            <div class="col-sm-7 col-12 px-m-0">
              <input class="form-control" id="cvv" name="cvv" minlength="3"
                     [required]="card.is_verified === 0 && cvv_optional === 0" #cvv_inp="ngModel"
                     [(ngModel)]="charge.cvv"
                     type="text" value="" [class.invalid]="capturePaymentDialog.submitted && cvv_inp.invalid"
                     maxlength="4"
                     (keypress)="_keyPress($event)">
              <span class="validation-err-msg"
                    *ngIf="capturePaymentDialog.submitted && cvv_inp.errors?.required">Cvv is required!</span>
              <span class="validation-err-msg"
                    *ngIf="capturePaymentDialog.submitted && (cvv_inp.errors?.maxlength || cvv_inp.errors?.minlength)">Cvv
              is invalid.</span>
            </div>
          </div>
          <div class="form-group row pt-m-18">
            <label class="col-sm-4 col-7  p-0">Capture charge:</label>
            <div class="col-sm-3 col-2 pt-sm-1 pt-0 ml-sm-3 ml-1 radio-align">
              <input class="form-check-input" type="radio" name="optionsRadios" (change)="charge.captured = 1"
                     checked="true" id="optionsRadios1"
                     value="true">
              <label class="form-check-label">
                Yes
              </label>
            </div>
            <div class="col-sm-3 col-2 pt-sm-1 pt-0">
              <input class="form-check-input" type="radio" name="optionsRadios" (change)="charge.captured = 0"
                     id="optionsRadios2" value="false">
              <label class="form-check-label">
                No
              </label>
            </div>
          </div>

          <!--start Kount-->
          <div class="form-group row pt-m-18" [hidden]="!hasSettings">
            <label class="col-sm-4 col-7 p-0">Enable Kount:</label>
            <div class="col-sm-3 col-2 pt-sm-1 pt-0 ml-sm-3 ml-1 radio-align">
              <input class="form-check-input" type="radio" name="kountRadios" (change)="is_kount = true"
                     [checked]="is_kount" id="kountRadios1"
                     value="true">
              <label class="form-check-label">
                Yes
              </label>
            </div>
            <div class="col-sm-3 col-2 pt-sm-1 pt-0">
              <input class="form-check-input" type="radio" name="kountRadios" (change)="is_kount = false"
                     [checked]="!is_kount" id="kountRadios2" value="false">
              <label class="form-check-label">
                No
              </label>
            </div>
          </div>

          <div class="form-group row pt-m-18" *ngIf="debtRepaymentEnabled">
            <label class="col-sm-4 col-7 p-0">Debt Repayment transaction:</label>
            <div class="col-sm-3 col-2 pt-sm-1 pt-0 ml-sm-3 ml-1 radio-align">
              <input class="form-check-input" type="radio" name="debtRepaymentRadios"
                     id="debtRepaymentYes" value="true" [checked]="is_debt_repayment" (change)="setDebtRepayment(true)">
              <label class="form-check-label" for="debtRepaymentYes">
                Yes
              </label>
            </div>
            <div class="col-sm-3 col-2 pt-sm-1 pt-0">
              <input class="form-check-input" type="radio" name="debtRepaymentRadios"
                     id="debtRepaymentNo" value="false" [checked]="!is_debt_repayment" (change)="setDebtRepayment(false)">
              <label class="form-check-label" for="debtRepaymentNo">
                No
              </label>
            </div>
          </div>

          <!--end kount-->
          <div class="form-group row pt-m-18">
            <label class="col-sm-4 col-7 p-0">Healthcare transaction:</label>
            <div class="col-sm-3 col-2 pt-sm-1 pt-0 ml-sm-3 ml-1 radio-align">
              <input type="radio" name="healthcareRadios" id="healthcareRadios1" value="true" class="form-check-input"
                     (change)="setHealthcare(true)" [checked]="is_health_care">
              <label class="form-check-label">
                <!--[(ngModel)]="charge.captured"-->
                Yes
              </label>
            </div>
            <div class="col-sm-3 col-2 pt-sm-1 pt-0">
              <input class="form-check-input" type="radio" name="healthcareRadios" id="healthcareRadios2" value="false"
                     [checked]="!is_health_care" (change)="setHealthcare(false)">
              <label class="form-check-label">
                No
              </label>
            </div>
          </div>
          <div *ngIf="is_health_care">
            <div class="form-group row pt-m-22">
              <label class="col-sm-4 col-7 p-0 mb-m-2">Healthcare type :</label>
              <div class="col-sm-4 col-2 p-0btn-group" id="status">
                <mat-button-toggle-group name="fontStyle" aria-label="Font Style" value="HSA">
                  <mat-button-toggle value="HSA" (change)="charge.healthcaretype = 'HSA'">HSA</mat-button-toggle>
                  <mat-button-toggle value="FSA" (change)="charge.healthcaretype = 'FSA'">FSA</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
            <div class="form-group row">
              <label for="prescription_amount" class="col-sm-4 col-12 p-0">Prescription:</label>
              <span class="error_sign col-2" id="prescription_amount1">!</span>
              <div class="col-sm-7 col-12 px-m-0">
                <input class="form-control" id="prescription_amount" name="prescription" appAmountValidator
                       [disabled]="!charge.amount" pattern="\d+(\.\d{1,2})?" step="0.01" min="0" max="9999999999999"
                       maxLength="14" #prescription="ngModel" [(ngModel)]="charge.prescription" type="number" value=""
                       (keyup)="checkTextField()" (keypress)="_keyPress($event)">
              </div>
            </div>
            <div class="form-group row">
              <label for="vision_amount" class="col-sm-4 col-12 p-0">Vision:</label>
              <span class="error_sign col-2" id="vision_amount1">!</span>
              <div class="col-sm-7 col-12 px-m-0">
                <input class="form-control" id="vision_amount" name="vision" appAmountValidator min="0"
                       [disabled]="!charge.amount" [maxLength]="14" #vision="ngModel" [(ngModel)]="charge.vision"
                       type="number"
                       value="" (keyup)="checkTextField()" (keypress)="_keyPress($event)" pattern="\d+(\.\d{1,2})?"
                       step="0.01">
              </div>
            </div>
            <div class="form-group row">
              <label for="clinic_amount" class="col-sm-4 col-12 p-0">Clinic:</label>
              <span class="error_sign col-2" id="clinic_amount1">!</span>
              <div class="col-sm-7 col-12 px-m-0">
                <input class="form-control" id="clinic_amount" name="clinic" appAmountValidator min="0"
                       [disabled]="!charge.amount" [maxLength]="14" #clinic="ngModel" [(ngModel)]="charge.clinic"
                       type="number"
                       value="" (keyup)="checkTextField()" (keypress)="_keyPress($event)" pattern="\d+(\.\d{1,2})?"
                       step="0.01">
              </div>
            </div>
            <div class="form-group row">
              <label for="dental_amount" class="col-sm-4 col-12 p-0">Dental:</label>
              <span class="error_sign col-2" id="dental_amount1">!</span>
              <div class="col-sm-7 col-12 px-m-0">
                <input class="form-control" [disabled]="!charge.amount" id="dental_amount" name="dental"
                       appAmountValidator min="0" [maxLength]="14" #dental="ngModel" [(ngModel)]="charge.dental"
                       type="number"
                       value="" (keyup)="checkTextField()" (keypress)="_keyPress($event)" pattern="\d+(\.\d{1,2})?"
                       step="0.01">
              </div>
            </div>
          </div>
          <!--          prepaid yes/no-->
          <div [hidden]="!hasPrepaid" class="form-group row pt-m-18">
            <label class="col-sm-4 col-7 p-0">Prepaid:</label>
            <div class="col-sm-3 col-2 pt-sm-1 pt-0 ml-sm-3 ml-1 radio-align">
              <input class="form-check-input" type="radio" name="prepaid"  id="prepaidYes"
                     (change)="is_prepaid = 'Yes' "   [checked]="is_prepaid === 'Yes'"
              >
              <label class="form-check-label" for="prepaidYes">
                Yes
              </label>
            </div>
            <div class="col-sm-3 col-2 pt-sm-1 pt-0">
              <input class="form-check-input" type="radio" name="prepaid" id="prepaidNo"
                     (change)="is_prepaid = 'No' "   [checked]="is_prepaid === 'No' || is_prepaid === null"
              >
              <label class="form-check-label" for="prepaidNo">
                No
              </label>
            </div>
          </div>
          <!--         prepaid yes/no-->
          <div
            *ngIf="(card_level === 'level2' || card_level === 'level3') && ( type === 'visa' || type === 'mastercard')">
            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">Sales tax:<i class="required-icon">*</i></label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <input class="form-control amountInput max-m-92" (keypress)="_keyPress($event)" appMinMaxValidator
                       [(ngModel)]="charge.sales_tax" [min]="0" [max]="999999999999" name="sales_tax"
                       #sales_tax="ngModel"
                       type="number" required [class.invalid]="sales_tax.invalid && capturePaymentDialog.submitted"/>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && sales_tax.errors?.required">Sales tax is required.</span>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && (sales_tax.errors?.min || sales_tax.errors?.max)">Sales tax is
                invalid.</span>
              </div>
              <ng-template #sales_taxTemplate>
                <div class="text-left">
                  <b>Description: </b>Sales tax amount.<br><b>Format:</b>
                  <br>Max Length = 12
                  <br>If no amount please enter 0.00
                </div>
              </ng-template>
              <div class="col-1 pl-0 pt-1"><i [tooltip]="sales_taxTemplate" container="body"
                                              class="fa pointer fa-lg fa-question-circle"></i></div>
            </div>
            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">Purchase order:<i class="required-icon">*</i></label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <input class="form-control max-m-92" [(ngModel)]="charge.purchase_order" #purchase_order="ngModel" required
                       [maxlength]="type === 'visa' ? 17 : 25" pattern="^[\w\-\s]+$" name="purcahse_order"
                       [class.invalid]="purchase_order.invalid && capturePaymentDialog.submitted" type="text"/>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && purchase_order.errors?.required">Purchase order is
                required.</span>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && (purchase_order.errors?.maxlength || purchase_order.errors?.pattern)">Purchase
                order is invalid.</span>
              </div>
              <ng-template #purchase_orderTemplate>
                <div class="text-left">
                  <b>Description: </b>The value used by the customer to identify an order. Issued by the buyer to the
                  seller.<br><b>Format:</b>
                  <br>Min Length = 0<br>Max Length = {{type === 'visa' ? '17' : '25'}}
                </div>
              </ng-template>
              <div class="col-1 pl-0 pt-1"><i [tooltip]="purchase_orderTemplate" container="body"
                                              class="fa pointer fa-lg fa-question-circle"></i></div>
            </div>
          </div>
          <div *ngIf="(card_level === 'level2' || card_level === 'level3') &&  type === 'amex'">
            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">Supplier reference number:<i class="required-icon">*</i></label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <input class="form-control max-m-92" #supplier_reference_number="ngModel"
                       [(ngModel)]="charge.supplier_reference_number" required maxlength="9" pattern="^[\w\-\s]+$"
                       name="supplier_reference_number" type="text"
                       [class.invalid]="supplier_reference_number.invalid && capturePaymentDialog.submitted"/>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && supplier_reference_number.errors?.required">Supplier reference
                number is required.</span>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && (supplier_reference_number.errors?.maxlength || supplier_reference_number.errors?.pattern)">Supplier
                reference number is invalid.</span>
              </div>
              <ng-template #supplier_reference_numberTemplate>
                <div class="text-left">
                  <b>Description: </b>This field contains a reference number that is used by American Express to obtain
                  supporting information on a charge from a merchant.
                  The number can be any combination of characters and numbers defined by the merchant.<br><b>Format:</b>
                  <br>Min Length = 0<br>Max Length = 9
                </div>
              </ng-template>
              <div class="col-1 pl-0 pt-1"><i [tooltip]="supplier_reference_numberTemplate" container="body"
                                              class="fa pointer fa-lg fa-question-circle"></i></div>
            </div>
            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">Customer reference ID:<i class="required-icon">*</i></label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <input class="form-control max-m-92" #customer_refernce_id="ngModel" [(ngModel)]="charge.customer_ref_id"
                       required
                       maxlength="17" pattern="^[\w\-\s]+$" name="customer_refernce_id" type="text"
                       [class.invalid]="customer_refernce_id.invalid && capturePaymentDialog.submitted"/>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && customer_refernce_id.errors?.required">Customer reference ID is
                required.</span>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && (customer_refernce_id.errors?.maxlength || customer_refernce_id.errors?.pattern)">Customer
                reference ID is invalid.</span>
              </div>
              <ng-template #customer_refernce_idTemplate>
                <div class="text-left">
                  <b>Description: </b>The reference identifier supplied by the Commercial Card
                  cardholder.<br><b>Format:</b>
                  <br>Min Length = 0<br>Max Length = 17
                </div>
              </ng-template>
              <div class="col-1 pl-0 pt-1"><i [tooltip]="customer_refernce_idTemplate" container="body"
                                              class="fa pointer fa-lg fa-question-circle"></i></div>
            </div>
            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">Sales tax:<i class="required-icon">*</i></label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <input class="form-control max-m-92" #amex_sales_tax="ngModel" [(ngModel)]="charge.sales_tax" required
                       appMinMaxValidator [min]="0" [max]="999999999999" name="amex_sales_tax" type="number"
                       (keypress)="_keyPress($event)"
                       [class.invalid]="amex_sales_tax.invalid && capturePaymentDialog.submitted"/>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && amex_sales_tax.errors?.required">Sales tax is required.</span>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && ( amex_sales_tax.errors?.min ||  amex_sales_tax.errors?.max)">Sales
                tax is invalid.</span>
              </div>
              <ng-template #amex_sales_taxTemplate>
                <div class="text-left">
                  <b>Description: </b>Sales tax amount.<br><b>Format:</b>
                  <br>Max Length = 12
                  <br>If no amount please enter 0.00
                </div>
              </ng-template>
              <div class="col-1 pl-0 pt-1"><i [tooltip]="amex_sales_taxTemplate" container="body"
                                              class="fa pointer fa-lg fa-question-circle"></i></div>
            </div>
            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">AMEX descriptor:<i class="required-icon">*</i></label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <input class="form-control max-m-92" #charge_descriptor="ngModel"
                       [class.invalid]="charge_descriptor.invalid && capturePaymentDialog.submitted" required
                       name="charge_descriptor" type="text" [(ngModel)]="charge.amex_descriptor" minlength="5"
                       maxlength="40"
                       pattern="^[\w\-\s]+$"/>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && charge_descriptor.errors?.required">AMEX descriptor is
                required.</span>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && (charge_descriptor.errors?.maxlength || charge_descriptor.errors?.pattern)">AMEX
                descriptor is invalid.</span>
              </div>
              <ng-template #charge_descriptorTemplate>
                <div class="text-left">
                  <b>Description: </b>The value of the Transaction Advice Addendum field, displays descriptive
                  information
                  about a transactions on a customer's AMEX card statement.<br><b>Format:</b>
                  <br>Min Length = 0<br>Max Length = 40
                </div>
              </ng-template>
              <div class="col-1 pl-0 pt-1"><i [tooltip]="charge_descriptorTemplate" container="body"
                                              class="fa pointer fa-lg fa-question-circle"></i></div>
            </div>
            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">Ship to zip:<i class="required-icon">*</i></label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <input class="form-control max-m-92" #ship_to_zip="ngModel" [(ngModel)]="charge.ship_to_zip" required
                       maxlength="10" name="ship_to_zip" type="text"
                       [class.invalid]="ship_to_zip.invalid && capturePaymentDialog.submitted"/>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && ship_to_zip?.errors?.required">Zip code is required.</span>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && ship_to_zip?.errors?.maxlength">Zip code is invalid.</span>
              </div>
              <ng-template #shiptoZipTemplate>
                <div class="text-left">
                  <b>Description: </b>The postal code for the address to which the goods are being
                  shipped.<br><b>Format:</b>
                  <br>Min Length = 0<br>Max Length = 10
                </div>
              </ng-template>
              <div class="col-1 pl-0 pt-1"><i [tooltip]="shiptoZipTemplate" container="body"
                                              class="fa pointer fa-lg fa-question-circle"></i></div>
            </div>
          </div>
          <div *ngIf="card_level == 'level3' && ( type === 'visa' || type === 'mastercard')">
            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">Ship to zip:<i class="required-icon">*</i></label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <input class="form-control max-m-92" #ship_to_zip="ngModel" [(ngModel)]="charge.ship_to_zip" required
                       maxlength="10" name="ship_to_zip" type="text"
                       [class.invalid]="ship_to_zip.invalid && capturePaymentDialog.submitted"/>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && ship_to_zip.errors?.required">Zip code is required.</span>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && ship_to_zip.errors?.maxlength">Zip code is invalid.</span>
              </div>
              <ng-template #shiptoZipTemplate>
                <div class="text-left">
                  <b>Description: </b>The postal code for the address to which the goods are being
                  shipped.<br><b>Format:</b>
                  <br>Min Length = 0<br>Max Length = 10
                </div>
              </ng-template>
              <div class="col-1 pl-0 pt-1"><i [tooltip]="shiptoZipTemplate" container="body"
                                              class="fa pointer fa-lg fa-question-circle"></i></div>
            </div>
            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">Ship from zip:<i class="required-icon">*</i></label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <input class="form-control max-m-92" #shipFromZip="ngModel"
                       [class.invalid]="shipFromZip.invalid && capturePaymentDialog.submitted" name="shipFromZip"
                       type="text"
                       [(ngModel)]="charge.ship_from_zip" required maxlength="10"/>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && shipFromZip.errors?.required">Ship from zip is required.</span>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && shipFromZip.errors?.maxlength">Ship from zip is invalid.</span>
              </div>
              <ng-template #shipFromZipTemplate>
                <div class="text-left">
                  <b>Description: </b>The postal code for the address from which the goods were
                  shipped.<br><b>Format:</b>
                  <br>Min Length = 0<br>Max Length = 10
                </div>
              </ng-template>
              <div class="col-1 pl-0 pt-1"><i [tooltip]="shipFromZipTemplate" container="body"
                                              class="fa pointer fa-lg fa-question-circle"></i></div>
            </div>
            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">Tax category:<i class="required-icon">*</i></label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <select class="form-control max-m-92" [class.invalid]="tax_category.invalid && capturePaymentDialog.submitted"
                        name="tax_category" #tax_category="ngModel" [(ngModel)]="charge.tax_category" required>
                  <option *ngFor="let item of taxCategories" [value]="item.value">{{item.text}}</option>
                </select>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && tax_category.errors?.required">Tax category is required.</span>
              </div>
              <ng-template #tax_categoryTemplate>
                <div class="text-left">
                  <b>Description: </b>The type of tax. Formerly established through TaxCategory
                  messages.<br><b>Format:</b>
                  <br>Min Length = 1<br>Max Length = 50
                </div>
              </ng-template>
              <div class="col-1 pl-0 pt-1"><i [tooltip]="tax_categoryTemplate" container="body"
                                              class="fa pointer fa-lg fa-question-circle"></i></div>
            </div>
            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">Tax type:<i class="required-icon">*</i></label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <input class="form-control max-m-92"
                       [class.invalid]="additional_tax_type.invalid && capturePaymentDialog.submitted" maxlength="50"
                       name="additional_tax_type" type="text" #additional_tax_type="ngModel"
                       [(ngModel)]="charge.tax_type"
                       required pattern="^[\w\-\s]+$"/>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && additional_tax_type.errors?.required">Tax type is
                required.</span>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && (additional_tax_type.errors?.maxlength || additional_tax_type.errors?.pattern)">Tax
                type is invalid.</span>
              </div>
              <ng-template #additional_tax_typeTemplate>
                <div class="text-left">
                  <b>Description: </b>The type of tax. For example, VAT, NATIONAL, Service Tax.<br><b>Format:</b>
                  <br>Min Length = 1<br>Max Length = 50
                </div>
              </ng-template>
              <div class="col-1 pl-0 pt-1"><i [tooltip]="additional_tax_typeTemplate" container="body"
                                              class="fa pointer fa-lg fa-question-circle"></i></div>
            </div>
            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">Tax amount:<i class="required-icon">*</i></label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <input class="form-control max-m-92 amountInput" #additional_tax_amount="ngModel" (keypress)="_keyPress($event)"
                       required appMinMaxValidator [min]="0" [max]="99999999999999" name="additional_tax_amount"
                       type="number"
                       [(ngModel)]="charge.tax_amount"
                       [class.invalid]="additional_tax_amount.invalid && capturePaymentDialog.submitted"/>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && additional_tax_amount.errors?.required">Tax amount is
                required.</span>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && (additional_tax_amount.errors?.min || additional_tax_amount.errors?.max)">Tax
                amount is invalid.</span>
              </div>
            </div>
            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">Duty charges:<i class="required-icon">*</i></label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <input class="form-control max-m-92 amountInput"
                       [class.invalid]="dutyCharges.invalid && capturePaymentDialog.submitted" appMinMaxValidator
                       [min]="0"
                       [max]="999999999999" name="dutyCharges" type="number" #dutyCharges="ngModel"
                       (keypress)="_keyPress($event)" [(ngModel)]="charge.duty_charges" required/>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && dutyCharges.errors?.required">Duty charges are required.</span>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && (dutyCharges.errors?.max || dutyCharges.errors?.min)">Duty
                charges are invalid.</span>
              </div>
              <ng-template #dutyChargesTemplate>
                <div class="text-left">
                  <b>Description: </b>Indicates the total charges for any import or export duties included in the
                  order.<br><b>Format:</b>
                  <br>Max Length = 12
                  <br>If no amount please enter 0.00
                </div>
              </ng-template>
              <div class="col-1 pl-0 pt-1"><i [tooltip]="dutyChargesTemplate" container="body"
                                              class="fa pointer fa-lg fa-question-circle"></i></div>
            </div>
            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">Shipping charges:<i class="required-icon">*</i></label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <input class="form-control max-m-92 amountInput"
                       [class.invalid]="shippingCharges.invalid && capturePaymentDialog.submitted" required
                       appMinMaxValidator
                       [min]="0" [max]="9999" name="shippingCharges" type="number" #shippingCharges="ngModel"
                       (keypress)="_keyPress($event)" [(ngModel)]="charge.shipping_charges"/>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && shippingCharges.errors?.required">Shipping charges are
                required.</span>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && (shippingCharges.errors?.max || shippingCharges.errors?.min)">Shipping
                charges are invalid.</span>
              </div>
              <ng-template #shippingChargesTemplate>
                <div class="text-left">
                  <b>Description: </b>The dollar amount for shipping or freight charges applied to a product or
                  transaction.<br><b>Format:</b>
                  <br>Max Length = 4
                  <br>If no amount please enter 0.00
                </div>
              </ng-template>
              <div class="col-1 pl-0 pt-1"><i [tooltip]="shippingChargesTemplate" container="body"
                                              class="fa pointer fa-lg fa-question-circle"></i></div>
            </div>
            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">Destination country code:<i class="required-icon">*</i></label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <select class="form-control max-m-92"
                        [class.invalid]="destinationCountryCode.invalid && capturePaymentDialog.submitted" required
                        name="destinationCountryCode" type="text" #destinationCountryCode="ngModel"
                        [(ngModel)]="charge.destination_country_code">
                  <option *ngFor="let contryCode of countryCodeList"
                          [value]="contryCode.val">{{contryCode.text}}</option>
                </select>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && destinationCountryCode.errors?.required">Destination country
                code is required.</span>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && (destinationCountryCode.errors?.minlength || destinationCountryCode.errors?.maxlength)">Destination
                country code is invalid.</span>
              </div>
              <ng-template #destinationCountryCodeTemplate>
                <div class="text-left">
                  <b>Description: </b>The destination country code indicator.
                </div>
              </ng-template>
              <div class="col-1 pl-0 pt-1"><i [tooltip]="destinationCountryCodeTemplate" container="body"
                                              class="fa pointer fa-lg fa-question-circle"></i></div>
            </div>
          </div>
          <div *ngIf="card_level == 'level3' && ( type === 'visa')">
            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">Customer VAT Number:<i class="required-icon">*</i></label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <input class="form-control max-m-92"
                       [class.invalid]="customerVATNumber.invalid && capturePaymentDialog.submitted"
                       required pattern="^[\w\-\s]+$" name="customerVATNumber" type="text" #customerVATNumber="ngModel"
                       [(ngModel)]="charge.customer_vat_number" maxlength="13"/>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && customerVATNumber.errors?.required">Customer VAT Number is
                required.</span>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && (customerVATNumber.errors?.maxlength || customerVATNumber.errors?.pattern)">Customer
                VAT Number is invalid.</span>
              </div>
              <ng-template #customerVATNumberTemplate>
                <div class="text-left">
                  <b>Description: </b>The international description code of the overall goods or services being
                  supplied.<br><b>Format:</b>
                  <br>Min Length = 0<br>Max Length = 4
                </div>
              </ng-template>
              <div class="col-1 pl-0 pt-1"><i [tooltip]="customerVATNumberTemplate" container="body"
                                              class="fa pointer fa-lg fa-question-circle"></i></div>
            </div>
            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">Summary commodity code:<i class="required-icon">*</i></label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <input class="form-control max-m-92"
                       [class.invalid]="summaryCommodityCode.invalid && capturePaymentDialog.submitted" required
                       pattern="^[\w\-\s]+$" name="summaryCommodityCode" type="text" #summaryCommodityCode="ngModel"
                       [(ngModel)]="charge.summary_commodity_code" maxlength="4"/>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && summaryCommodityCode.errors?.required">Summary commodity code
                is required.</span>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && (summaryCommodityCode.errors?.maxlength || summaryCommodityCode.errors?.pattern)">Summary
                commodity code is invalid.</span>
              </div>
              <ng-template #summaryCommodityCodeTemplate>
                <div class="text-left">
                  <b>Description: </b>The international description code of the overall goods or services being
                  supplied.<br><b>Format:</b>
                  <br>Min Length = 0<br>Max Length = 4
                </div>
              </ng-template>
              <div class="col-1 pl-0 pt-1"><i [tooltip]="summaryCommodityCodeTemplate" container="body"
                                              class="fa pointer fa-lg fa-question-circle"></i></div>
            </div>
            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">Vat invoice:<i class="required-icon">*</i></label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <input class="form-control max-m-92" [class.invalid]="vatInvoice.invalid && capturePaymentDialog.submitted"
                       maxlength="15" name="vatInvoice" type="text" #vatInvoice="ngModel"
                       [(ngModel)]="charge.vat_invoice"
                       required pattern="^[\w\-\s]+$"/>
                <span class="validation-err-msg" *ngIf="capturePaymentDialog.submitted && vatInvoice.errors?.required">Vat
                invoice is required.</span>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && (vatInvoice.errors?.maxlength || vatInvoice.errors?.pattern)">Vat
                invoice is invalid.</span>
              </div>
              <ng-template #vatInvoiceTemplate>
                <div class="text-left">
                  <b>Description: </b>The Value Added Tax (VAT) invoice number associated with the
                  transaction.<br><b>Format:</b>
                  <br>Min Length = 0<br>Max Length = 15
                </div>
              </ng-template>
              <div class="col-1 pl-0 pt-1"><i [tooltip]="vatInvoiceTemplate" container="body"
                                              class="fa pointer fa-lg fa-question-circle"></i></div>
            </div>
            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">Order date:<i class="required-icon">*</i></label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <input class="form-control max-m-92 calander-input-icon" #orderDate="ngModel"
                       [class.invalid]="orderDate.invalid && capturePaymentDialog.submitted" required name="orderDate"
                       type="text" [bsConfig]="bsDateConfig" placeholder="MM/DD/YYYY" bsDatepicker
                       [(ngModel)]="charge.order_date"/>
                <span class="validation-err-msg"
                      *ngIf="capturePaymentDialog.submitted && orderDate?.errors?.required">Order date is required.</span>
              </div>
              <ng-template #orderDateTemplate>
                <div class="text-left">
                  <b>Description: </b>The date the order was processed.<br><b>Format:</b>
                  <br>Min Length = 0<br>Max Length = 10
                </div>
              </ng-template>
              <div class="col-1 pl-0 pt-1"><i [tooltip]="orderDateTemplate" container="body"
                                              class="fa pointer fa-lg fa-question-circle"></i></div>
            </div>
            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">Tax rate:<i class="required-icon">*</i></label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <input class="form-control max-m-92" [class.invalid]="taxRate.invalid && capturePaymentDialog.submitted" required
                       appMinMaxValidator [max]="999999999999" [min]="0" name="taxRate" type="number" #taxRate="ngModel"
                       (keypress)="_keyPress($event)" [(ngModel)]="charge.tax_rate"/>
                <span class="validation-err-msg" *ngIf="capturePaymentDialog.submitted && taxRate.errors?.required">Tax
                rate is required.</span>
                <span class="validation-err-msg" *ngIf="capturePaymentDialog.submitted && taxRate.errors?.max">Tax rate is
                invalid.</span>
              </div>
              <ng-template #taxRateTemplate>
                <div class="text-left">
                  <b>Description: </b>The type of tax rate. This field is used if taxCategory is not
                  used.<br><b>Format:</b>
                  <br>Min Length = 1<br>Max Length = 12
                  <br>If no amount please enter 0.00
                </div>
              </ng-template>
              <div class="col-1 pl-0 pt-1"><i [tooltip]="taxRateTemplate" container="body"
                                              class="fa pointer fa-lg fa-question-circle"></i></div>
            </div>
            <!--AVS and BIN-->
            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">AVS option:</label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <select class="form-control max-m-92" id="avs_parameters" name="avs_parameters"
                        [(ngModel)]="charge.avs_parameters">
                  <option [value]="0">Choose an AVS option</option>
                  <option *ngFor="let avsParameter of avsParameters"
                          [value]="avsParameter.key">{{avsParameter.name}}</option>
                </select>
              </div>
            </div>

            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">BIN location option:</label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <select class="form-control max-m-92" id="bin_location_parameters" name="bin_location_parameters"
                        [(ngModel)]="charge.bin_location_parameters">
                  <option [value]="0">Choose a BIN location option</option>
                  <option *ngFor="let binLocationParameter of binLocationParameters"
                          [value]="binLocationParameter.key">{{binLocationParameter.name}}</option>
                </select>
              </div>
            </div>

            <div class="form-group row mb-m-1">
              <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-0">BIN card type option:</label>
              <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-11 px-m-0">
                <select class="form-control max-m-92" id="bin_card_type_parameters" name="bin_card_type_parameters"
                        [(ngModel)]="charge.bin_card_type_parameters">
                  <option [value]="0">Choose a BIN card type option</option>
                  <option *ngFor="let binCardTypeParameter of binCardTypeParameters"
                          [value]="binCardTypeParameter.key">{{binCardTypeParameter.name}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-6 col-sm-12 col-xl-6 px-m-0">
          <div class="form-group row  pt-m-18">
            <label for="address_line1" class="col-sm-4 col-12 p-0">Address:<i class="required-icon"
                                                                    *ngIf="addopt === 0 || card_level !== 'level1'">*</i></label>
            <span class="error_sign" id="address_line11">!</span>
            <div class="col-sm-7 col-12 px-m-0 mb-m-18">
              <div *ngIf="isApiLoaded">
              <input class="form-control" type="text" id="address_line1" name="street" placeholder="Address line 1"
                     #street="ngModel" [(ngModel)]="card.address1" [required]="addopt === 0 || card_level !== 'level1'"
                     maxlength="200" [class.invalid]="street.invalid && capturePaymentDialog.submitted"
                     ngx-google-places-autocomplete
                     [options]="options"
                     (onAddressChange)="handleAddressChange($event)"/>

              <span class="validation-err-msg" *ngIf="capturePaymentDialog.submitted && street.errors?.required">Address
              line 1 is required.</span>
              <span class="validation-err-msg" *ngIf="capturePaymentDialog.submitted && street.errors?.maxlength">Address
              line 1 max length exceed.</span>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 p-0"></label>
            <span class="error_sign" id="address_line21">!</span>
            <div class="col-sm-7 col-12 px-m-0 mb-m-18">
              <input class="form-control" type="text" id="address_line2" name="street2" #street2="ngModel"
                     [class.invalid]="street2.invalid && capturePaymentDialog.submitted" [(ngModel)]="card.address2"
                     placeholder="Address  Line 2">
              <span class="validation-err-msg" *ngIf="capturePaymentDialog.submitted && street2.errors?.maxlength">Address
              line 2 max length exceed.</span>
            </div>
          </div>
          <div class="form-group row">
            <label for="city" class="col-sm-4 col-12 p-0"></label>
            <div class="col-sm-7 col-12 px-m-0 mb-m-18">
              <input class="form-control" type="text" id="city" name="city" placeholder="City" #City="ngModel"
                     [class.invalid]="City.invalid && capturePaymentDialog.submitted" maxlength="200"
                     [required]="addopt === 0 || card_level !== 'level1'" [(ngModel)]="card.city"/>
              <span class="validation-err-msg" *ngIf="capturePaymentDialog.submitted && City.invalid">City is
              required.</span>
            </div>
          </div>
          <div class="form-group row">
            <label for="state" class="col-4 p-0"></label>
            <div class="col-sm-7 col-12 px-m-0 mb-m-18">
              <select class="form-control" id="state" name="state" #state="ngModel"
                      [required]="addopt === 0 || card_level !== 'level1'"
                      [class.invalid]="state.invalid && capturePaymentDialog.submitted" [(ngModel)]="card.state">
                <option [value]="null">Choose a state</option>
                <option *ngFor="let state of states" [value]="state.name">{{state.name}}</option>
              </select>
              <span class="validation-err-msg" *ngIf="capturePaymentDialog.submitted && state.invalid">State is
              required.</span>
            </div>
          </div>
          <div class="form-group row pt-m-8">
            <label class="col-4 d-sm-flex d-none p-0"></label>
            <span class="error_sign" id="zip1">!</span>
            <div class="col-sm-7 col-10 px-m-0 mb-m-18">
              <input class="form-control" type="text" id="zip"
                     [class.invalid]="zip.invalid && capturePaymentDialog.submitted" name="zip" placeholder="Zip code"
                     #zip="ngModel" [required]="addopt === 0 || card_level !== 'level1'" maxlength="15" minlength="2"
                     [(ngModel)]="card.zip"/>
              <span class="validation-err-msg" *ngIf="capturePaymentDialog.submitted && zip.errors?.required">Zip code is
              required.</span>
              <span class="validation-err-msg" *ngIf="zip.errors?.maxlength">Zip code is invalid.</span>
            </div>
            <ng-template #zipTemplate>
              <div class="text-left">
                <b>Description: </b>The cardholder's billing ZIP code.<br><b>Format:</b>
                <br>Min Length = 2<br>Max Length = 15
              </div>
            </ng-template>
            <div class="col-sm-1 col-2 pl-0 pt-1 text-sm-left text-right"><i [tooltip]="zipTemplate" container="body"
                                            class="fa pointer fa-lg fa-question-circle"></i></div>
          </div>
          <div class="form-group row pt-m-18">
            <label class="col-sm-4 col-12 p-0">Country:<i class="required-icon"
                                                *ngIf="addopt === 0 || card_level !== 'level1'">*</i></label>
            <div class="col-sm-7 col-12 px-m-0">
              <select class="form-control" id="country" [required]="addopt === 0 || card_level !== 'level1'"
                      [class.invalid]="country.invalid && capturePaymentDialog.submitted" name="country"
                      #country="ngModel"
                      [(ngModel)]="card.country">
                <option [value]="" disabled>Choose a country</option>
                <option *ngFor="let country of countries" [value]="country.key">{{country.name}}</option>
              </select>
              <span class="validation-err-msg" *ngIf="capturePaymentDialog.submitted && country.errors?.required">Country
              is required.</span>
            </div>
          </div>
          <div class="form-group row pt-m-18">
            <label class="col-sm-4 col-12 p-0">Email address:</label>
            <span class="error_sign" id="email1">!</span>
            <div class="col-sm-7 col-12 px-m-0">
              <label><input class="mr-2" type="checkbox" (change)="updateemail($event)" value="angular">Use my current
                information for invoice.</label>
              <input class="form-control" type="email" type="email" #receipt_email="ngModel"
                     [(ngModel)]="charge.receipt_email" name="receipt_email" id="receipt_email" value=""
                     [class.invalid]="capturePaymentDialog.submitted && receipt_email.invalid"
                     pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}"/>
              <span class="validation-err-msg"
                    *ngIf="capturePaymentDialog.submitted && (receipt_email.errors?.receipt_email || receipt_email.errors?.pattern) &&  !receipt_email.errors?.required">Email
              address is invalid.</span>
            </div>
          </div>
          <div class="form-group row pt-m-18">
            <label class="col-sm-4 col-12 p-0">Phone:</label>
            <span class="error_sign" id="phone1">!</span>
            <div class="col-sm-7 col-12 px-m-0">
              <div class="row m-0">
                <span class="input-group-addon last-d col-2 text-center p-2">+1</span>
                <input class="form-control col-10" id="receipt_phone" name="receipt_phone" #receipt_phone="ngModel"
                       [class.invalid]="capturePaymentDialog.submitted && receipt_phone.invalid"
                       [(ngModel)]="charge.receipt_phone" placeholder="(000) 000-0000" type="tel" appPhoneMask
                       (blur)="updateSelectedPhone($event)" maxlength="14" value=""
                       pattern="((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}"/>
                <span class="validation-err-msg phn-err-msg"
                      *ngIf="capturePaymentDialog.submitted && receipt_phone.invalid">Phone number is invalid.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-sm-3 pt-5 pb-3 justify-content-end m-0">
        <button class="cancel_btn" (click)="cancelCharge()">Cancel</button>
        <app-loading-button (buttonClick)="capturePaymentDialog.invalid ? '' : callTerminalList()" [disable]=" (charge.amount < (charge.prescription + charge.vision + charge.clinic + charge.dental)) ||
checkTextField() " [loadFlag]="loadFlag" [buttonText]="'Charge customer'">
        </app-loading-button>
      </div>
    </form>
  </div>
</div>
</div>
